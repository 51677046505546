*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 99px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e2e4e8;
  border-radius: 99px;
  border: 2px solid #ffffff;
}
